import api from '@/utils/api'

export default {
    getLeagues(page, size, season, phase) {
        const filter = '"active": true'
        const range = '?seasonId=' + season.id + '&phase=' + phase
        return api.get('leagues/based-on-season' + (page ? range : '') + (season ? '' : `&filter=${encodeURIComponent('{' + filter + '}')}`))
    },

    getLeague(id) {
        return api.get(`leagues/${id}`)
    },

    getAllLeagues() {
        return api.get('leagues/all')
    },

    getRankingByLeague(leagueId, seasonId, phase = {}) {
        const filter = phase ? `?phase=${phase}&isCup=false` : null
        return api.get(`leagues/${leagueId}/season/${seasonId}/ranking` + filter)
    }
}
