<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" class="mx-0 px-0">
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="teams"
                        class="elevation-1 rounded-lg py-3 px-3"
                        hide-default-footer
                        :mobile-breakpoint="0"
                        :items-per-page="-1"
                    >
                        <template v-slot:item.two_points_percentage="{ item }">
                            <span class="font-weight-bold"> {{ option ? `${item.two_points_percentage}% (${item.cum.twoPointsMade} / ${item.cum.twoPointAttempts})` : `${item.two_points_percentage}%` }} </span>
                        </template>
                        <template v-slot:item.three_points_percentage="{ item }">
                            <span class="font-weight-bold"> {{ option ? `${item.three_points_percentage}% (${item.cum.threePointsMade} / ${item.cum.threePointAttempts})` : `${item.three_points_percentage}%` }} </span>
                        </template>
                        <template v-slot:item.free_throw_percentage="{ item }">
                            <span class="font-weight-bold"> {{ option ? `${item.free_throw_percentage}% (${item.cum.freeThrowsMade} / ${item.cum.freeThrowAttempts})` : `${item.free_throw_percentage}%` }} </span>
                        </template>
                    </v-data-table>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            teams: {
                type: Array,
                default: null
            },
            option: {
                type: Number,
                defautl: null
            }
        },
        data() {
            return {
                headers: [
                    { text: 'Team', value: 'name', align: 'start' },
                    { text: 'GP', value: 'gp', align: 'center' },
                    { text: 'Pts', value: 'points', align: 'center' },
                    { text: '2P%', value: 'two_points_percentage', width: '150px', align: 'center' },
                    { text: '3P%', value: 'three_points_percentage', width: '150px', align: 'center' },
                    { text: 'FT%', value: 'free_throw_percentage', width: '150px', align: 'center' },
                    { text: 'OR', value: 'offensiveRebounds', align: 'center' },
                    { text: 'DR', value: 'defensiveRebounds', align: 'center' },
                    { text: 'TR', value: 'rebounds', align: 'center' },
                    { text: 'AST', value: 'assists', align: 'center' },
                    { text: 'STL', value: 'steals', align: 'center' },
                    { text: 'TO', value: 'turnovers', align: 'center' },
                    { text: 'BLK', value: 'blockedShots', align: 'center' },
                    { text: 'FC', value: 'personalFouls', align: 'center' }
                ]
            }
        },
        watch: {
            option: {
                handler() {
                    this.prepareData()
                }
            },
            teams: {
                handler() {
                    this.prepareData()
                }
            }
        },
        created() {
            if (this.generic) {
                this.headers = [{ text: 'Team', value: 'team' }, ...this.headers]
            }
            this.prepareData()
        },
        methods: {
            prepareData() {
                this.teams.forEach(team => {
                    team.points = this.option ? team.cum.points : team.avg.points.toFixed(1)
                    team.gp = team.stats.length
                    team.two_points_percentage = this.option ? (team.cum.twoPointsMade / (team.cum.twoPointAttempts ? team.cum.twoPointAttempts : 1) * 100).toFixed(1) : (team.avg.twoPointsMade / (team.avg.twoPointAttempts ? team.avg.twoPointAttempts : 1) * 100).toFixed(1)
                    team.free_throw_percentage = this.option ? (team.cum.freeThrowsMade / (team.cum.freeThrowAttempts ? team.cum.freeThrowAttempts : 1) * 100).toFixed(1) : (team.avg.freeThrowsMade / (team.avg.freeThrowAttempts ? team.avg.freeThrowAttempts : 1) * 100).toFixed(1)
                    team.three_points_percentage = this.option ? (team.cum.threePointsMade / (team.cum.threePointAttempts ? team.cum.threePointAttempts : 1) * 100).toFixed(1) : (team.avg.threePointsMade / (team.avg.threePointAttempts ? team.avg.threePointAttempts : 1) * 100).toFixed(1)
                    team.offensiveRebounds = this.option ? team.cum.offensiveRebounds : team.avg.offensiveRebounds.toFixed(1)
                    team.defensiveRebounds = this.option ? team.cum.defensiveRebounds : team.avg.defensiveRebounds.toFixed(1)
                    team.rebounds = this.option ? team.cum.rebounds : team.avg.rebounds.toFixed(1)
                    team.assists = this.option ? team.cum.assists : team.avg.assists.toFixed(1)
                    team.steals = this.option ? team.cum.steals : team.avg.steals.toFixed(1)
                    team.turnovers = this.option ? team.cum.turnovers : team.avg.turnovers.toFixed(1)
                    team.blockedShots = this.option ? team.cum.blockedShots : team.avg.blockedShots.toFixed(1)
                    team.personalFouls = this.option ? team.cum.personalFouls : team.avg.personalFouls.toFixed(1)
                })
            }
        }
    }
</script>
