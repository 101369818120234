<template>
    <v-row no-gutters class="my-0 py-0 px-2">
        <v-col cols="12" class="white rounded-lg px-2 py-1 mb-5" align="center">
            <v-row no-gutters>
                <v-col cols="12" align="center">
                    <v-select
                        v-model="season"
                        color="orange"
                        item-color="orange"
                        label="Σεζόν"
                        :items="seasons"
                        item-text="name"
                        return-object
                        style="width: 500px;"
                    />
                </v-col>
                <v-col cols="12" align="center">
                    <v-select
                        v-model="phase"
                        label="Φάση"
                        color="orange"
                        item-color="orange"
                        :items="[{title:'Προκριματικά', value:'placements'}, {title:'Regular Season', value:'regular_season'}, {title:'PlayOffs', value:'playoffs'}]"
                        item-text="title"
                        item-value="value"
                        style="width: 500px;"
                    />
                </v-col>
                <v-col cols="12" align="center">
                    <v-select
                        v-model="league"
                        color="orange"
                        item-color="orange"
                        :items="leagues"
                        label="League"
                        item-text="title"
                        return-object
                        item-value="id"
                        style="width: 500px;"
                        @change="fetchStats()"
                    />
                </v-col>
                <v-col cols="12" align="center">
                    <v-chip-group
                        v-model="option"
                        column
                        mandatory
                        style="display: inline-block;"
                    >
                        <v-chip
                            v-for="(item, index) in ['Παίκτες', 'Ομάδα']"
                            :key="index"
                            filter
                            filter-icon="mdi-basketball"
                            active-class="custom-orange selected-chip"
                            color="white"
                            large
                            mandatory
                            class="font-weight-bold"
                        >
                            {{ item }}
                        </v-chip>
                    </v-chip-group>
                </v-col>
            </v-row>
        </v-col>
        <v-col v-for="(statHeader, index) in statsHeaders" :key="index" cols="12" md="3" class="mb-5 px-1">
            <stat-card :loading="loading" :stat-header="statHeader" :stats="getDisplayData(statHeader)" :option="option === 1" @scrollToStats="scrollToStats()" />
        </v-col>
        <v-col ref="stats" cols="12" class="white rounded-lg" align="center">
            <v-chip-group
                v-model="option2"
                column
                mandatory
                style="display: inline-block;"
            >
                <v-chip
                    v-for="(item, index) in ['Μέσοι Όροι', 'Σύνολο']"
                    :key="index"
                    filter
                    filter-icon="mdi-basketball"
                    active-class="custom-orange selected-chip"
                    color="white"
                    large
                    mandatory
                    class="font-weight-bold"
                >
                    {{ item }}
                </v-chip>
            </v-chip-group>
        </v-col>
        <v-col cols="12">
            <players-stats v-if="!option" :players="playerStats" :option="option2" :generic="true" />
            <teams-stats v-else :teams="stasData" :option="option2" :generic="true" />
        </v-col>
    </v-row>
</template>

<script>
    import StatCard from '@/components/StatCard'
    import statsService from '@/services/stats.js'
    import leagueService from '@/services/league.js'
    import PlayersStats from '@/components/PlayersStats'
    import TeamsStats from '@/components/TeamsStats'
    import seasonService from '@/services/season.js'
    import moment from 'moment'

    export default {
        components: { StatCard, PlayersStats, TeamsStats },
        data() {
            return {
                statsHeaders: [
                    { text: 'Pts', value: 'points' },
                    { text: '2P Made', value: 'twoPointsMade' },
                    { text: '3P Made', value: 'threePointsMade' },
                    { text: 'FT Made', value: 'freeThrowsMade' },
                    { text: 'OR', value: 'offensiveRebounds' },
                    { text: 'DR', value: 'defensiveRebounds' },
                    { text: 'TR', value: 'rebounds' },
                    { text: 'AST', value: 'assists' },
                    { text: 'STL', value: 'steals' },
                    { text: 'TO', value: 'turnovers' },
                    { text: 'BLK', value: 'blockedShots' },
                    { text: 'FC', value: 'personalFouls' }
                ],
                stasData: null,
                playerStats: [],
                teamStats: [],
                leagues: [],
                league: null,
                option: 0,
                option2: 0,
                type: 'placements',
                phase: 'regular_season',
                season: null,
                seasons: [],
                loading: false,
                maxRound: 1
            }
        },
        watch: {
            season: {
                handler(value) {
                    if (value) {
                        this.filter = null
                        this.fetchAllLeagues()
                    }
                }
            },
            league: {
                handler() {
                    this.fetchStats()
                }
            },
            phase: {
                handler() {
                    this.fetchStats()
                }
            }
        },
        created() {
            this.fetchAllSeasons()
        },
        methods: {
            fetchAllSeasons() {
                seasonService.getSeasons(1, 100).then(resp => {
                    this.seasons = resp.data.content
                    this.season = resp.data.content.filter(element => moment().isBetween(moment(element.startDate), moment(element.endDate)))[0]
                })
            },
            getDisplayData(statHeader) {
                const temp = JSON.parse(JSON.stringify(this.option === 1 ? this.statsData : this.playerStats.filter(element => element.stats.length >= this.maxRound * 0.55)))
                temp.sort((a, b) => b.avg[statHeader.value] - a.avg[statHeader.value])
                return temp.length > 5 ? temp.slice(0, 5) : temp
            },
            fetchStats() {
                if (this.season) {
                    this.loading = true
                    this.$store.dispatch('setLeagueFilter', this.league)
                    statsService.getStatsByLeague(this.league.id, this.phase, this.season.id).then(resp => {
                        this.statsData = resp.data
                        this.calculateTeamsStats(resp.data)
                        this.calculatePlayersStats(resp.data)
                        this.getPlayersStats()
                    })
                }
            },
            fetchAllLeagues() {
                leagueService.getLeagues(1, 100, this.season, this.phase).then(resp => {
                    this.leagues = resp.data
                    this.league = this.$store.getters.getLeagueFilter ? this.$store.getters.getLeagueFilter : resp.data[0]
                })
            },
            calculateTeamsStats(data) {
                data.forEach(team => {
                    this.teamStats = []
                    team.players.forEach(player => {
                        this.teamStats = this.teamStats.concat(player.stats)
                    })
                    team.stats = this.groupByMatch()
                    team.avg = this.getAverageStats(team)
                    team.cum = this.getCummulativeStats(team)
                    team.gp = team.stats.length
                    this.maxRound = team.stats.length
                })
                this.stasData = data
            },
            findMatches() {
                const matches = new Set()
                this.teamStats.forEach(stat => {
                    matches.add(stat.matchId)
                })
                return matches
            },
            groupByMatch() {
                const matches = this.findMatches()
                const data = []
                matches.forEach(match => {
                    const stats = {
                        points: 0,
                        rebounds: 0,
                        assists: 0,
                        steals: 0,
                        turnovers: 0,
                        blockedShots: 0,
                        twoPointsMade: 0,
                        twoPointAttempts: 0,
                        threePointsMade: 0,
                        threePointAttempts: 0,
                        freeThrowsMade: 0,
                        freeThrowAttempts: 0,
                        offensiveRebounds: 0,
                        defensiveRebounds: 0,
                        personalFouls: 0
                    }
                    this.teamStats.forEach(stat => {
                        if (match === stat.matchId) {
                            for (const key of Object.keys(stats)) {
                                stats[key] += stat[key]
                            }
                        }
                    })
                    data.push(stats)
                })
                return data
            },
            getPlayersStats() {
                this.playerStats = []
                this.stasData.forEach(team => {
                    this.playerStats = this.playerStats.concat(team.players)
                })
                this.loading = false
                return this.playerStats
            },
            calculatePlayersStats(data) {
                data.forEach(team => {
                    team.players.forEach(player => {
                        player.team = team.name
                        player.teamLogo = team.logo
                        player.avg = this.getAverageStats(player)
                        player.cum = this.getCummulativeStats(player)
                    })
                })
                this.stasData = data
            },
            getCummulativeStats(entity) {
                const stats = {
                    points: 0,
                    rebounds: 0,
                    assists: 0,
                    steals: 0,
                    turnovers: 0,
                    blockedShots: 0,
                    twoPointsMade: 0,
                    twoPointAttempts: 0,
                    threePointsMade: 0,
                    threePointAttempts: 0,
                    freeThrowsMade: 0,
                    freeThrowAttempts: 0,
                    offensiveRebounds: 0,
                    defensiveRebounds: 0,
                    personalFouls: 0
                }
                entity.stats.filter((element) => !element.cup).forEach(element => {
                    for (const key of Object.keys(stats)) {
                        stats[key] += element[key]
                    }
                })
                return stats
            },
            getAverageStats(entity) {
                const stats = {
                    points: 0,
                    rebounds: 0,
                    assists: 0,
                    steals: 0,
                    turnovers: 0,
                    blockedShots: 0,
                    twoPointsMade: 0,
                    twoPointAttempts: 0,
                    threePointsMade: 0,
                    threePointAttempts: 0,
                    freeThrowsMade: 0,
                    freeThrowAttempts: 0,
                    offensiveRebounds: 0,
                    defensiveRebounds: 0,
                    personalFouls: 0
                }
                const temp = entity.stats.filter((element) => !element.cup)
                temp.forEach(element => {
                    for (const key of Object.keys(stats)) {
                        stats[key] += element[key]
                    }
                })

                Object.keys(stats).forEach(element => {
                    if (temp.length) {
                        stats[element] = stats[element] / temp.length
                    } else {
                        stats[element] = 0
                    }
                })
                return stats
            },
            scrollToStats() {
                this.$refs.stats.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }
</script>

<style scoped>
.v-chip::before {
    background-color: white !important;
}

.selected-chip {
    color: white;
}
</style>
