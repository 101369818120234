<template>
    <v-container fluid>
        <v-row np-gutters>
            <v-col cols="12" class="mx-0 px-0">
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="players.filter((player) => player.active)"
                        class="elevation-1 rounded-lg py-3 px-3"
                        hide-default-footer
                        :mobile-breakpoint="0"
                        :items-per-page="-1"
                    >
                        <template v-slot:item.two_points_percentage="{ item }">
                            <span class="font-weight-bold"> {{ option ? `${item.two_points_percentage}% (${item.cum.twoPointsMade} / ${item.cum.twoPointAttempts})` : `${item.two_points_percentage}%` }} </span>
                        </template>
                        <template v-slot:item.three_points_percentage="{ item }">
                            <span class="font-weight-bold"> {{ option ? `${item.three_points_percentage}% (${item.cum.threePointsMade} / ${item.cum.threePointAttempts})` : `${item.three_points_percentage}%` }} </span>
                        </template>
                        <template v-slot:item.free_throw_percentage="{ item }">
                            <span class="font-weight-bold"> {{ option ? `${item.free_throw_percentage}% (${item.cum.freeThrowsMade} / ${item.cum.freeThrowAttempts})` : `${item.free_throw_percentage}%` }} </span>
                        </template>
                    </v-data-table>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            players: {
                type: Array,
                default: null
            },
            option: {
                type: Number,
                defautl: null
            },
            generic: {
                type: Boolean,
                defautl: false
            }
        },
        data() {
            return {
                headers: [
                    { text: 'Player', value: 'player', align: 'start' },
                    {
                        text: '# Number',
                        value: 'jersey',
                        align: 'center'
                    },
                    { text: 'GP', value: 'gp', align: 'start' },
                    { text: 'Pts', value: 'points' },
                    { text: '2P%', value: 'two_points_percentage', width: '150px', align: 'center' },
                    { text: '3P%', value: 'three_points_percentage', width: '150px', align: 'center' },
                    { text: 'FT%', value: 'free_throw_percentage', width: '150px', align: 'center' },
                    { text: 'OR', value: 'offensiveRebounds', align: 'start' },
                    { text: 'DR', value: 'defensiveRebounds', align: 'start' },
                    { text: 'TR', value: 'rebounds', align: 'start' },
                    { text: 'AST', value: 'assists', align: 'start' },
                    { text: 'STL', value: 'steals', align: 'start' },
                    { text: 'TO', value: 'turnovers', align: 'start' },
                    { text: 'BLK', value: 'blockedShots', align: 'start' },
                    { text: 'FC', value: 'personalFouls', align: 'start' }
                ],
                maxGames: 1
            }
        },
        watch: {
            generic: {
                handler() {
                    if (this.generic) {
                        this.headers.splice(1, 0, { text: 'Team', value: 'team' })
                    }
                }
            },
            option: {
                handler() {
                    this.prepareData()
                }
            },
            players: {
                handler() {
                    this.prepareData()
                }
            }
        },
        created() {
            if (this.generic) {
                this.headers.splice(1, 0, { text: 'Team', value: 'team' })
            }
            this.prepareData()
        },
        methods: {
            prepareData() {
                this.players.forEach(player => {
                    if (player.stats.length > this.maxGames) {
                        this.maxGames = player.stats.length
                    }
                    player.player = `${player.name} ${player.surname}`
                    player.points = this.option ? player.cum.points : player.avg.points.toFixed(1)
                    player.gp = player.stats.length
                    player.two_points_percentage = this.option ? (player.cum.twoPointsMade / (player.cum.twoPointAttempts ? player.cum.twoPointAttempts : 1) * 100).toFixed(1) : (player.avg.twoPointsMade / (player.avg.twoPointAttempts ? player.avg.twoPointAttempts : 1) * 100).toFixed(1)
                    player.free_throw_percentage = this.option ? (player.cum.freeThrowsMade / (player.cum.freeThrowAttempts ? player.cum.freeThrowAttempts : 1) * 100).toFixed(1) : (player.avg.freeThrowsMade / (player.avg.freeThrowAttempts ? player.avg.freeThrowAttempts : 1) * 100).toFixed(1)
                    player.three_points_percentage = this.option ? (player.cum.threePointsMade / (player.cum.threePointAttempts ? player.cum.threePointAttempts : 1) * 100).toFixed(1) : (player.avg.threePointsMade / (player.avg.threePointAttempts ? player.avg.threePointAttempts : 1) * 100).toFixed(1)
                    player.offensiveRebounds = this.option ? player.cum.offensiveRebounds : player.avg.offensiveRebounds.toFixed(1)
                    player.defensiveRebounds = this.option ? player.cum.defensiveRebounds : player.avg.defensiveRebounds.toFixed(1)
                    player.rebounds = this.option ? player.cum.rebounds : player.avg.rebounds.toFixed(1)
                    player.assists = this.option ? player.cum.assists : player.avg.assists.toFixed(1)
                    player.steals = this.option ? player.cum.steals : player.avg.steals.toFixed(1)
                    player.turnovers = this.option ? player.cum.turnovers : player.avg.turnovers.toFixed(1)
                    player.blockedShots = this.option ? player.cum.blockedShots : player.avg.blockedShots.toFixed(1)
                    player.personalFouls = this.option ? player.cum.personalFouls : player.avg.personalFouls.toFixed(1)
                })
            }
        }
    }
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    left: 0;
    background: white;
}
</style>
