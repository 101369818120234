import api from '@/utils/api'

export default {
    getSeasons(page, size) {
        const range = '?range=' + encodeURIComponent(`[${page - 1},${size}]` + `&sort=${encodeURIComponent('[ "date", "DESC]')}`)
        return api.get('seasons' + (page ? range : ''))
    },

    getSeason(id) {
        return api.get(`seasons/${id}`)
    }
}
