<template>
    <v-card :loading="loading" class="rounded-lg my-0 py-0 grey lighten-4">
        <template slot="progress">
            <v-progress-linear color="orange" indeterminate />
        </template>
        <v-row no-gutters dense align="center">
            <v-col cols="6" align="start" class="pl-3">
                {{ statHeader.text }}
            </v-col>
            <v-col cols="6" align="end">
                Full list
                <v-btn icon @click="$emit('scrollToStats')">
                    <v-icon
                        color="orange"
                        medium
                    >
                        mdi-arrow-right-thin-circle-outline
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="option ? teamHeaders : playerHeaders"
            :items="stats"
            class="rounded-lg py-3 font-weight-bold"
            hide-default-footer
            disable-sort
            :mobile-breakpoint="0"
            :items-per-page="-1"
            :item-class="leaderStatClass"
            @click:row="handleClick"
        >
            <template v-slot:item.games="{ item }">
                <v-row dense align="center" height="60px">
                    <v-col cols="12" align="start" class="py-2">
                        {{ item.stats.length }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.avg="{ item }">
                <v-row dense align="center">
                    <v-col cols="12" align="start" class="py-2">
                        {{ item.avg[statHeader.value].toFixed(1) }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.cum="{ item }">
                <v-row dense align="center">
                    <v-col cols="12" align="start" class="py-2">
                        {{ item.cum[statHeader.value] }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.player="{ item }">
                <v-row dense align="center" justify="center">
                    <v-col cols="12" align="start" class="py-2">
                        <v-img
                            v-if="item.image"
                            :src="`${assetsUrl}/${item.image}`"
                            height="50px"
                            width="50px"
                            contain
                            class="mr-2"
                            style="display: inline-flex;"
                            @error="item.image = null"
                        />
                        <v-icon v-else style="display: inline-flex; font-size: 50px; vertical-align: baseline;" class="orange--text" large> mdi-account </v-icon>
                        <v-img
                            v-if="item.teamLogo"
                            :src="`${assetsUrl}/${item.teamLogo}`"
                            height="50px"
                            width="50px"
                            contain
                            style="display: inline-flex;"
                        />
                        <v-icon v-else style="display: inline-flex; font-size: 50px; vertical-align: baseline;" class="orange--text"> mdi-basketball </v-icon>
                        <div>{{ `${item.name} ${item.surname}` }}</div>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.name="{ item }">
                <v-row dense align="center" justify="center">
                    <v-col cols="12" align="start" class="py-2">
                        <img
                            :src="`${assetsUrl}/${item.logo}`"
                            height="50px"
                            width="50px"
                            contain
                        >
                        <div>{{ `${item.name}` }}</div>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        props: {
            option: {
                type: Boolean,
                default: true
            },
            stats: {
                type: Array,
                default: null
            },
            statHeader: {
                type: Object,
                default: null
            },
            loading: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com',
                teamHeaders: [
                    { text: 'Team', value: 'name', width: '150px' },
                    { text: 'Games', value: 'games' },
                    { text: 'Avg', value: 'avg' },
                    { text: 'Total', value: 'cum' }
                ],
                playerHeaders: [
                    { text: 'Player', value: 'player', width: '150px' },
                    { text: 'Games', value: 'games' },
                    { text: 'Avg', value: 'avg' },
                    { text: 'Total', value: 'cum' }
                ]
            }
        },
        watch: {
        },
        methods: {
            leaderStatClass(item) {
                if (this.stats.indexOf(item) === 0) {
                    return 'leader-stat'
                }
            },
            handleClick(row) {
                if (this.option) {
                    this.$router.push({ path: `/Team/${row.id}` })
                } else {
                    this.$router.push({ path: `/Player/${row.id}` })
                }
            }
        }
    }
</script>

<style>

.leader-stat {
    /* background: linear-gradient(90deg, #f05252 0%, #f67c47 50%, rgb(252, 178, 42) 100%) !important; */

    /* color: white; */
    font-weight: 600;
}

</style>
